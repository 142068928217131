import { Injectable } from '@angular/core';
import { TenantApiService } from '@api/tenant/tenant.api.service';
import { Observable } from 'rxjs';
import { Tenant } from '../../types/Tenant';
import { ActionResponse } from '../../types/DataResult';

@Injectable()
export class SuperTenantRepository {
  public constructor(public readonly tenantApi: TenantApiService) {}

  public getTenant(id: string): Observable<Tenant> {
    return this.tenantApi.getTenantDataByTenantId(id);
  }

  public deleteTenant(tenantId: string): Observable<ActionResponse> {
    return this.tenantApi.deleteTenant(tenantId);
  }

  public createTenant(tenant: Tenant): Observable<ActionResponse> {
    return this.tenantApi.createTenant(tenant);
  }

  public updateTenant(tenant: Tenant): Observable<ActionResponse> {
    return this.tenantApi.updateTenantById(tenant);
  }
}
